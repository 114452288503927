	/*
  	Flaticon icon font: Flaticon
  	Creation date: 15/06/2020 06:32
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-play:before { content: "\f100"; }
.flaticon-search:before { content: "\f101"; }
.flaticon-data-management:before { content: "\f102"; }
.flaticon-pdf:before { content: "\f103"; }
.flaticon-right:before { content: "\f104"; }
.flaticon-left:before { content: "\f105"; }
.flaticon-chat:before { content: "\f106"; }
.flaticon-video-call:before { content: "\f107"; }
.flaticon-search-1:before { content: "\f108"; }
.flaticon-time-management:before { content: "\f109"; }
.flaticon-layers:before { content: "\f10a"; }
.flaticon-double-left-angle-arrows:before { content: "\f10b"; }
.flaticon-double-angle-arrow-pointing-to-right:before { content: "\f10c"; }
.flaticon-reload:before { content: "\f10d"; }
.flaticon-eye:before { content: "\f10e"; }
.flaticon-chat-1:before { content: "\f10f"; }
.flaticon-share:before { content: "\f110"; }